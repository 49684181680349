<template>
  <div class="page-main">销售合同列表</div>
</template>

<script>
export default {}
</script>

<style lang="less" scoped>
.page-main {
}
</style>
